import { FaTelegramPlane } from "react-icons/fa";
import { FaSquareXTwitter, FaTelegram } from "react-icons/fa6";

const SocialButton = ({ label, link, children }) => {
  return (
    <div className="opacity-70 hover:opacity-100 hover:scale-125 hover:shadow-black/30 hover:shadow-lg transition-all duration-200 cursor-pointer">
      <a href={link} target="_blank" rel="noreferrer">{children}</a>
    </div>
  );
};

const socials = [
  {
    icon: <FaTelegram size={40}/>,
    link: "https://t.me/theblockchainarmy",
  },
  {
    icon: <FaSquareXTwitter size={40}/>,
    link: "https://twitter.com/blockchainarmy_",
  }
];
export const SocialButtons = () => {
  return (
    <div className="w-full flex flex-row items-center justify-center px-5 gap-x-5">
      {socials.map((s) => (
        <SocialButton key={s.link} link={s.link}>{s.icon}</SocialButton>
      ))}
    </div>
  );
};