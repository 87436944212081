import { HomeKpi } from "./HeaderKpis";

export const HomeHeader = ({ data }) => {
  const formatToTrillions = (number) => {
    return Number((number / 1_000_000_000_000).toFixed(2));
  };
  const formatToBilions = (number) => {
    return Number((number / 1_000_000_000).toFixed(2));
  };
  const roundVariation = (n) => {
    return n.toFixed(2);
  };
  return (
    <div className="w-full py-3 gap-x-2 gap-y-2 px-2 grid grid-cols-3 md:grid-cols-6 sticky top-16 bg-neutral-900 z-10">
      <HomeKpi
        label={"Global market cap"}
        value={`$${formatToTrillions(
          data.first.quote.USD.total_market_cap
        )}T`}
        variation={roundVariation(
          data.first.quote.USD.total_market_cap_yesterday_percentage_change
        )}
      />
      <HomeKpi
        label={"24h Volume"}
        value={`$${formatToBilions(
          data.first.quote.USD.total_volume_24h
        )}B`}
        variation={roundVariation(
          data.first.quote.USD.total_volume_24h_yesterday_percentage_change
        )}
      />
      <HomeKpi
        label={"BTC Dominance"}
        value={`${data.first.btc_dominance.toFixed(2)}%`}
        variation={data.first.btc_dominance}
        hidePerc={true}
      />
      <HomeKpi
        label={"ETH Dominance"}
        value={`${data.first.eth_dominance.toFixed(2)}%`}
        variation={data.first.eth_dominance}
        hidePerc={true}
      />
      <HomeKpi
        label={"Fear & Greed"}
        value={`${data.feg[0].value}/100`}
        variation={data.first.eth_dominance}
        hidePerc={true}
        isFEG={true}
        fegToday={data.feg[0].value}
        fegYesterday={data.feg[1].value}

      />
      <HomeKpi
        label={"24h DEXs Volume"}
        value={`$${formatToBilions(data.dexes.total24h)}B`}
        variation={data.dexes.change_1d}
      />
    </div>
  );
};
