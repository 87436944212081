import { useWeb3ModalTheme } from "@web3modal/wagmi/react";

const WalletAndAuth = () => {
const { setThemeMode, setThemeVariables } = useWeb3ModalTheme()

  setThemeMode('dark')
  /* themeVariables: {
   
    '--w3m-overlay-background-color':'red',
    '--wui-color-accent-100': 'red',
    '--wui-border-radius-m': '3px'
  } */
setThemeVariables({
  '--w3m-font-family': 'Oswald, sans-serif',
  '--w3m-accent': '#D22326',
  '--w3m-color-mix': '#262626',
  '--w3m-color-mix-strength': 70,
  '--w3m-border-radius-master': '0px'
})
  return (
    <div className="">
      <div className="w3-button !uppercase">
        <w3m-button
        label="CONNECT WALLET"
        />
      </div>
    </div>
  );
};

export default WalletAndAuth;
