import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { convertDateFormat } from '../../../utils/chartOptions'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const colors = {
  yellow: "rgb(252, 224, 112)",
  blue: "rgb(89, 112, 245)",
  purple: "rgb(191, 112, 223)",
  red: "rgb(210, 35, 38)",
  redAlpha: "rgb(210, 35, 38, 0.5)",
  white: "rgb(237, 237, 237)",
  whiteAlpha: "rgb(237, 237, 237, 0.5)",
  lightRed: "rgb(255, 133, 133)",
  lightRedAlpha: "rgb(255, 133, 133, 0.5)",
};

const PeLinechart = ({ numbers }) => {
  const peChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "",
      },
    },
    scales: {
      x: {
        grid: {
          color: "transparent",
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        grid: {
          color: "#525252",
        },
        ticks: {
          color: colors.white,
          callback: function (value, index, ticks) {
            return "$" + (Math.round(value * 100) / 100).toFixed(2);
          },
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: colors.red,
        },
      },
  /*     y2: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: colors.lightRed,
        },
      }, */
    },
  };
  const labels = numbers.map((el) => convertDateFormat(el.date));
  const dtx = {
    labels,
    datasets: [
      {
        label: "P/E",
        data: numbers.map((el) => el.peRatio),
        borderColor: colors.red,
        backgroundColor: colors.redAlpha,
        yAxisID: "y1",
        borderWidth: 2,
        borderJoinStyle: "round",
        radius:  1,
        pointStyle: false,
        pointBackgroundColor: 'transparent',
        point: {
          radius: 0,
          pointStyle: 'cirlce',
          backgroundColor: 'transparent'
        }
      },
      {
        label: "Price",
        data: numbers.map((el) => el.price),
        borderColor: colors.white,
        borderWidth: 2,
        borderJoinStyle: "round",
        backgroundColor: colors.whiteAlpha,
        yAxisID: "y",
        radius: 1,
        pointStyle: false,
        point: {
          radius: 0
        }
      },
 /*      {
        label: "Revenue Share APY",
        data: numbers.map((el) => el.apy),
        borderColor: colors.lightRed,
        borderWidth: 3,
        borderJoinStyle: "round",
        backgroundColor: colors.lightRedAlpha,
        yAxisID: "y2",
      }, */
    ],
  };
  return <div className="w-full h-full p-5 border border-neutral-300/20  rounded-sm"><Line options={peChartoptions} data={dtx} /></div>;
};

export default PeLinechart;
