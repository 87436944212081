const PeSingleProjectInfos = ({ infos }) => {
  return (
    <div className="h-fit md:h-[400px] flex flex-col">
      <div className="text-lg md:text-xl mb-2 md:mb-2.5">Project Informations</div>
      <div className="w-full border  border-neutral-300/20  rounded-sm flex-grow flex flex-col items-strart justify-center">
        <div className="flex flex-row justify-between items-center text-md px-5 h-[12.5%] border-b border-b-neutral-300/20">
          <div className="text-neutral-400 font-light text-md">Market Cap</div>{" "}
          <div className="font-semibold">
            ${infos.marketCap.toLocaleString()}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center  text-md px-5 h-[12.5%] border-b border-b-neutral-300/20">
          <div className="text-neutral-400 font-light text-md">Token Price </div>{" "}
          <div className="font-semibold">MISSING</div>
        </div>
        <div className="flex flex-row justify-between items-center text-md px-5 h-[12.5%] border-b border-b-neutral-300/20">
          <div className="text-neutral-400 font-light text-md">
            Fully Diluted Valuation
          </div>{" "}
          <div className="font-semibold">
            ${infos.fullyDilutedMarketCap.toLocaleString()}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center text-md px-5 h-[12.5%] border-b border-b-neutral-300/20">
          <div className="text-neutral-400 font-light text-md"> Market Cap</div>{" "}
          <div className="font-semibold">
            ${infos.marketCap.toLocaleString()}
          </div>
        </div>
       {/*  <div className="flex flex-row justify-between items-center text-md px-5 h-[12.5%] border-b border-b-neutral-300/20">
          <div className="text-neutral-400 font-light text-md">
            24h {infos.name} Volume:
          </div>{" "}
          <div className="font-semibold">
            ${infos.dailyVolume.toLocaleString()}
          </div>
        </div> */}
        <div className="flex flex-row justify-between items-center text-md px-5 h-[12.5%] border-b border-b-neutral-300/20">
          <div className="text-neutral-400 font-light text-md">
            Token Liquidity:
          </div>{" "}
          <div className="font-semibold">
            ${infos.liquidity.toLocaleString()}
          </div>
        </div>
        <div className="flex flex-row justify-between items-center text-md px-5 h-[12.5%] border-b border-b-neutral-300/20">
          <div className="text-neutral-400 font-light text-md">Circulating supply:</div>{" "}
          <div className="font-semibold">
            {infos.circulatingSupply.toLocaleString()}
          </div>
        </div>
       {/*  <div className="flex flex-row justify-between items-center text-md px-5 h-[12.5%]">
          <div className="text-neutral-400 font-light text-md">Holders:</div>{" "}
          <div className="font-semibold">{infos.holders.toLocaleString()}</div>
        </div> */}
      </div>
    </div>
  );
};
export default PeSingleProjectInfos;
