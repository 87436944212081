import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/Home";
import Sidebar from "./components/SideBar";
import Header from "./components/Header";
import MobileMenu from "./components/MobileMenu";
import PEAnalysis from "./pages/PEAnalysis";
import BurnPage from "./pages/Burn";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/pe-analysis",
    element: <PEAnalysis />,
  },
  {
    path: "/burn-dashboards",
    element: <BurnPage />,
  },
]);
function App() {
  return (
    <div className="w-full md:h-screen md:flex md:flex-row">
      <Sidebar />
      <Header />
      <MobileMenu />
      <div className="flex flex-grow bg-neutral-900 overflow-y-auto">
        <RouterProvider router={router} />
      </div>
    </div>
  );
}

export default App;
