/* import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  convertDateFormatCompressed,
} from "../../../utils/chartOptions"; */
/* 
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
); */
/* 
function generateChartData(labels, dataset) {
  return {
    labels,
    datasets: [
      {
        label: "Holders",
        data: dataset.map((e) => e.holders),
        backgroundColor: "#D22326",
        borderSkipped: "bottom",
        borderRadius: 8,
      },
    ],
  };
} */
const PeHoldersChart = ({ numbers }) => {
 /*  const peChartoptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
    },
    scales: {
      y: {
        grid: {
          color: "#e0e0e0",
        },
        ticks: {
          color: "#e0e0e0",
        },
      },
      x: {
        ticks: {
          color: "#e0e0e0",
        },
      },
    },
  };

  const labels = numbers.map((el) => convertDateFormatCompressed(el.date));
 */
  return (
    <div className="max-md:hidden w-full h-[60%] flex flex-col">
      <div className="text-xl mb-2.5">Holders</div>
      <div className="flex flex-grow max-h-48 w-full border border-neutral-300/20  p-2.5 rounded-sm items-center justify-center relative">
        <img src="/black-logo.webp" alt="bc logo" className="h-full w-auto"/>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ibm-plex-mono text-xl font-bold">COMING SOON</div>
       {/*  <div className="text-md mb-2 w-full border-b border-b-neutral-300/30">{col1.title}</div>
        <p className="text-xs">{col1.paragraph}</p> */}
      </div>
      {/* <div className="flex flex-grow w-full border border-neutral-300/20  rounded-sm p-5">
        <Bar
          options={peChartoptions}
          data={generateChartData(labels, numbers)}
        />
      </div> */}
    </div>
  );
};

export default PeHoldersChart;
