const Button = ({ label, icon, action, active }) => {
  return (
    <button
      className={`${
        active ? "bg-ui-red" : "bg-ui-black"
      } rounded-[4px] p-4 w-full flex flex-row items-center justify-start hover:bg-ui-red/70 gap-x-4 hover:-translate-y-1 hover:shadow-black/30 hover:shadow-lg transition-all duration-200 `}
    >
      {icon} {label}
    </button>
  );
};
export default Button;
