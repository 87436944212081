import BcLogo from "./BCLogo";
import Chip from "./Chip";
import WalletAndAuth from "./WalletAndAuth";

const Header = () => {
  return (
    <div className="w-screen h-16 bg-ui-dark-gray pl-5 pr-8 border-b border-b-neutral-300/20 fixed top-0 flex flex-row items-center justify-between z-50">
      <BcLogo />
      <div className="flex flex-row items-center gap-x-2.5 max-md:ml-auto max-md:pr-2">
        <div className="hidden md:flex flex-row gap-x-1.5 items-center">
          <Chip label='KPI A' value={"$249"}/>
          <Chip label='KPI B' value={"$3.4m"}/>
        </div>
        <WalletAndAuth />
      </div>
    </div>
  );
};
export default Header;
