
import { useState, useEffect, useRef } from "react";

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const ref = useRef();
  useEffect(() => {
    setContentHeight(ref && ref.current.scrollHeight + 50);
  }, []);
  return (
    <div className={`border rounded-sm border-neutral-300/20  mb-5`}>
      <div
        className={`accordion-header w-full flex gap-2 items-center justify-between text-md ${
          isOpen ? "open" : ""
        } md:px-3 px-2 py-2 cursor-pointer leading-none`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className=" text-lg text-neutral-200 font-semibold">{title}</h4>
        <span>
          <svg
            width="15"
            height="15"
            viewBox="0 0 23 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`transition-all duration-300 ease-in-out ${
              isOpen ? "rotate-45" : ""
            }`}
          >
            <path d="M11.5 0V22" stroke="#e5e5e5" strokeWidth="1.5" />
            <path d="M22.5 11L0.500001 11" stroke="#e5e5e5" strokeWidth="1.5" />
          </svg>
        </span>
      </div>
      <div
        className="accordion-content overflow-hidden transition-max-height duration-300 ease-in-out flex justify-end max-md:w-full"
        style={{
          maxHeight: isOpen ? contentHeight + "px" : "0",
        }}
      >
        <div ref={ref} className={` w-full md:px-3 px-2 py-2 text-xs font-light text-neutral-300/70`}>
          <div>{content}</div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
