export function convertDateFormat(dateStr) {
  // Dividi la stringa della data in un array usando il carattere '-' come separatore
  const date = new Date(dateStr);

  // Ottieni i componenti della data
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth() ritorna mesi da 0 a 11
  const year = date.getUTCFullYear();

  // Costruisci la stringa nel formato DD-MM-YYYY
  return `${day}-${month}-${year}`;
}
export function convertDateFormatCompressed(dateStr) {
  // Dividi la stringa della data in un array usando il carattere '-' come separatore
  const dateParts = dateStr.split("-");

  // Assegna le parti dell'array alle variabili corrispondenti
  /*  const year = dateParts[0]; */
  const month = dateParts[1];
  const day = dateParts[2];

  // Ritorna la data nel nuovo formato 'dd-mm-yyyy'
  return `${day}-${month}`;
}
export function convertTimestampToDateString(timestamp) {
  // Convertire il timestamp in millisecondi
  const date = new Date(timestamp * 1000);

  // Estrarre l'anno, il mese e il giorno
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // getUTCMonth() ritorna un valore tra 0 e 11
  const day = String(date.getUTCDate()).padStart(2, "0");

  // Formattare la data nel formato "YYYY-MM-DD"
  return `${year}-${month}-${day}`;
}

export function generateRandomHexColor(opacity = 100) {
  // Genera un numero casuale tra 0x000000 e 0xFFFFFF
  const randomNum = Math.floor(Math.random() * 0xffffff);

  // Converti il numero in una stringa esadecimale, aggiungendo padding con zeri se necessario
  const hexString = randomNum.toString(16).padStart(6, "0");

  // Calcola il valore esadecimale dell'opacità
  // Clamp dell'opacità tra 0 e 100, quindi converti in scala 0-255 e poi a stringa esadecimale
  const opacityHex = Math.round(Math.min(Math.max(opacity, 0), 100) * 2.55)
    .toString(16)
    .padStart(2, "0");

  // Ritorna la stringa esadecimale con il prefisso '#' e l'opacità
  return `#${hexString}${opacityHex}`;
}
