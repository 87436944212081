import PeTableHeaderElement from "./PeTableHeaderElement";

const PeTableHeader = ({ clickedSort, selectedId }) => {
  return (
    <div className="w-full max-w-full overflow-auto grid grid-cols-3 md:grid-cols-8 border-b border-neutral-300/20  text-sm pr-[6px] bg-neutral-900">
      <PeTableHeaderElement
        name="Name"
        id="name"
        clickedSort={clickedSort}
        selectedId={selectedId}
      />{" "}
      <PeTableHeaderElement
        name="PE"
        id="peRatio"
        clickedSort={clickedSort}
        selectedId={selectedId}
      />
      <PeTableHeaderElement
        name="Market Cap"
        id="marketCap"
        clickedSort={clickedSort}
        selectedId={selectedId}
      />
      <PeTableHeaderElement
        name="Holders Revenue (24h)"
        id="holdersRevenue"
        clickedSort={clickedSort}
        selectedId={selectedId}
        className={'hidden md:flex'}
      />
      <PeTableHeaderElement
        name="Revenue (24h)"
        id="revenue1day"
        clickedSort={clickedSort}
        selectedId={selectedId}
        className={'hidden md:flex'}

      />
      <PeTableHeaderElement
        name="Revenue (7d)"
        id="revenue1week"
        clickedSort={clickedSort}
        selectedId={selectedId}
        className={'hidden md:flex'}

      />
      <PeTableHeaderElement
        name="Revenue (30d)"
        id="revenue1month"
        clickedSort={clickedSort}
        selectedId={selectedId}
        className={'hidden md:flex'}
      />
      <PeTableHeaderElement
        name="% to holders"
        id="percToHolders"
        clickedSort={clickedSort}
        selectedId={selectedId}
        className={'hidden md:flex'}
      />
    </div>
  );
};
export default PeTableHeader;
