"use client";
import LinkButton from "./LinkButton";
import { Army, BurnIcon, PEAnalysis, BestFarms } from "./icons/NavIcons";
export const navigationSettings = [
  {
    label: "Overview",
    path: "/",
    icon: <Army />,
  },
  {
    label: "P/E Analysis",
    path: "/pe-analysis",
    icon: <PEAnalysis />,
  },
  {
    label: "Burn Dashboards",
    path: "/burn-dashboards",
    subRoutes: [{}],
    icon: <BurnIcon />,
  },
  {
    label: "Work in progress...",
    path: "/best-farms",
    disabled: true,
    icon: <BestFarms />,
  },
];

const Navigation = () => {
  return (
    <div className="w-full pl-5 pr-5 flex flex-col gap-y-4">
      {navigationSettings.map((el) => {
        return (
          <LinkButton
            key={el.label}
            linkPath={el.path}
            label={el.label}
            icon={el.icon}
            disabled={el.disabled ? el.disabled : false}
          />
        );
      })}
    </div>
  );
};

export default Navigation;
