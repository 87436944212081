import { DexesOveriew } from "../components/containers/homepage/DexesOverview";
import { Dune } from "../components/containers/homepage/Dune";
import { Inflows } from "../components/containers/homepage/Inflows";
import { ProjectOfTheDay } from "../components/containers/homepage/ProjectOfTheDay";
import { TokenOverview } from "../components/containers/homepage/TokenOverview";
import { HomeHeader } from "../components/homepage/HomeHeader";
import Loader from "../components/Loader";
import { useGetHomeData } from "../hooks/use-get-home-data";
import { IoHourglassOutline } from "react-icons/io5";

function HomePage() {
  const { loading, homeData } = useGetHomeData();

  return (
    <div className="w-full h-fit min-h-screen flex items-center pt-16 pb-5">
      <div className="w-full flex flex-col items-center relative">
        {loading ? (
          <div className="w-full flex items-center justify-center flex-grow">
            <Loader />
          </div>
        ) : null}
        {homeData && !loading && <HomeHeader data={homeData} />}
        <div className="flex flex-col gap-y-10 2xl:grid 2xl:grid-cols-2 w-full">
          <Inflows />
          <ProjectOfTheDay />
          <Dune />
          <DexesOveriew />

          <div className="w-full">
            <TokenOverview />
          </div>
        </div>
      </div>
    </div>
  );
}
export default HomePage;
