const TwoColsDescription = ({ textHolders, textProject, token }) => {
  return (
    <div className="w-full grid grid-cols-2 gap-x-5 mb-5">
      <div className="h-32 w-full border border-neutral-300/20  p-2.5 rounded-sm flex flex-col justify-start relative">
        <img
          src="/black-logo.webp"
          alt="bc logo"
          className="h-full w-auto object-contain"
        />
        <div className="absolute w-[calc(100%-20px)] h-full flex flex-col justify-start ">
          <div className="text-md mb-2 w-full border-b border-b-neutral-300/30">
            Holders Revenue
          </div>
          <p className="text-xs">{textHolders ? textHolders : <span className="font-mono">NO DATA FOR {token.toUpperCase()}</span>}</p>
        </div>
      </div>
      <div className="h-32 w-full border border-neutral-300/20  p-2.5 rounded-sm flex flex-col justify-start relative">
        <img
          src="/black-logo.webp"
          alt="bc logo"
          className="h-full w-auto object-contain"
        />
        <div className="absolute w-[calc(100%-20px)] h-full flex flex-col justify-start ">
          <div className="text-md mb-2 w-full border-b border-b-neutral-300/30">
            Project Revenue
          </div>
          <p className="text-xs">{textProject ? textProject : <span className="font-mono">NO DATA FOR {token.toUpperCase()}</span>}</p>
        </div>
      </div>
    </div>
  );
};

export default TwoColsDescription;