import TableHeaderElement from "./TableHeaderElement";

const TableHeader = ({ clickedSort, selectedId }) => {
  return (
    <div className="w-full shrink-0  bg-neutral-900 max-w-full overflow-auto grid grid-cols-4 border-b border-neutral-300/20  text-sm pr-[6px] ">
      <TableHeaderElement
        name="Exchange"
        id="name"
        clickedSort={clickedSort}
        selectedId={selectedId}
        className={"col-span-2"}
      />{" "}
      <TableHeaderElement
        name="Reported Volume"
        dida="(24h)"
        id="volumeLast24HoursUsd"
        clickedSort={clickedSort}
        selectedId={selectedId}
      />{" "}
      <TableHeaderElement
        name="Reported Volume"
        dida="(24h change)"
        id="percentChangeVolumeLast24Hours"
        clickedSort={clickedSort}
        selectedId={selectedId}
      />
      {/* <TableHeaderElement
        name="Markets"
        id="spotMarketCount"
        clickedSort={clickedSort}
        selectedId={selectedId}
      />
      <TableHeaderElement
        name="Assets"
        id="spotAssetCount"
        clickedSort={clickedSort}
        selectedId={selectedId}
        className={"hidden md:flex"}
      /> */}
    </div>
  );
};
export default TableHeader;
