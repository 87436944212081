const TableRow = ({ infos, action }) => {
  return (
    <div
      className="cursor-pointer w-full grid grid-cols-4 md:grid-cols-4 border-b border-neutral-300/20  text-sm font-light "
    >
      <div className="col-span-2 flex items-center flex-row gap-x-2 border-r border-neutral-300/20  px-2 py-1 overflow-hidden whitespace-nowrap">
        <span className=" font-medium capitalize">{infos.name}</span>
      </div>
      <div className="w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
        {infos.metrics.volumeLast24HoursUsd
          ? infos.metrics.volumeLast24HoursUsd > 1_000_000
            ? (infos.metrics.volumeLast24HoursUsd / 1_000_000).toFixed() + "M"
            : (infos.metrics.volumeLast24HoursUsd / 1_000).toFixed(2) + "K"
          : 0}
      </div>
      <div
        className={` ${
          infos.metrics.percentChangeVolumeLast24Hours > 0
            ? "text-green-500"
            : "text-red-500"
        } w-full flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden`}
      >
        {infos.metrics.percentChangeVolumeLast24Hours
          ? infos.metrics.percentChangeVolumeLast24Hours.toFixed(2)
          : 0}
        %
      </div>
      {/* <div className="w-full hidden md:flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
        {infos.attributes.spotMarketCount}
      </div>
      <div className="w-full hidden md:flex flex-row  items-center  justify-end border-r border-neutral-300/20  px-2 py-1 overflow-hidden">
        {infos.attributes.spotAssetCount}
      </div> */}
    </div>
  );
};
export default TableRow;
