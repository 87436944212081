export const Army = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 6V18"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const BurnIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11.0982V4.17456C22 2.64729 21.36 2.03638 19.77 2.03638H15.73C14.14 2.03638 13.5 2.64729 13.5 4.17456V11.0982C13.5 12.6255 14.14 13.2364 15.73 13.2364H19.77C21.36 13.2364 22 12.6255 22 11.0982Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 20.2618V18.4291C22 16.9018 21.36 16.2909 19.77 16.2909H15.73C14.14 16.2909 13.5 16.9018 13.5 18.4291V20.2618C13.5 21.7891 14.14 22.4 15.73 22.4H19.77C21.36 22.4 22 21.7891 22 20.2618Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 13.3383V20.2619C10.5 21.7892 9.86 22.4001 8.27 22.4001H4.23C2.64 22.4001 2 21.7892 2 20.2619V13.3383C2 11.811 2.64 11.2001 4.23 11.2001H8.27C9.86 11.2001 10.5 11.811 10.5 13.3383Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 4.17456V6.00729C10.5 7.53456 9.86 8.14547 8.27 8.14547H4.23C2.64 8.14547 2 7.53456 2 6.00729V4.17456C2 2.64729 2.64 2.03638 4.23 2.03638H8.27C9.86 2.03638 10.5 2.64729 10.5 4.17456Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BestFarms = () => {
  return (
    <svg
      width="33"
      height="23"
      viewBox="0 0 33 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91231 11.1373C7.79436 11.1374 7.67774 11.1126 7.57005 11.0647C7.46236 11.0166 7.36605 10.9462 7.28737 10.8583C7.2097 10.7687 7.15178 10.6638 7.11719 10.5504C7.08248 10.437 7.0721 10.3176 7.0865 10.1999C7.0865 10.0995 8.45913 0 17.3757 0C17.5793 0.00123872 17.7756 0.0767337 17.9276 0.212323C18.0797 0.347913 18.1771 0.534301 18.2015 0.736536C18.4654 3.36441 17.6836 5.99046 16.0254 8.04609C14.2398 10.1106 11.5057 11.1373 7.91231 11.1373ZM16.5722 1.70742C11.2044 2.17613 9.44118 7.28724 8.92784 9.42988C10.0069 9.42519 11.0742 9.2068 12.0683 8.78735C13.0624 8.36788 13.9635 7.75562 14.7197 6.98593C15.9435 5.50169 16.6 3.63091 16.5722 1.70742Z"
        fill="white"
      />
      <path
        d="M7.91218 11.1262H7.81174C5.12227 10.7915 0 8.70462 0 3.24756C0.00091509 3.04261 0.0760195 2.84492 0.211419 2.69105C0.346819 2.53719 0.533352 2.43756 0.736536 2.4106C2.77254 2.20448 4.80814 2.80554 6.40563 4.08453C7.22609 4.88226 7.86118 5.85047 8.26616 6.92072C8.67115 7.99097 8.83597 9.13713 8.74915 10.2781C8.74926 10.3961 8.72449 10.5127 8.6765 10.6204C8.62841 10.7281 8.5581 10.8245 8.47016 10.9031C8.31661 11.0413 8.11875 11.1204 7.91218 11.1262ZM1.75206 4.05106C2.18729 7.53287 5.37894 8.81623 7.03057 9.25143C6.98348 7.77092 6.36335 6.36651 5.30083 5.33442C4.2965 4.51862 3.04587 4.06635 1.75206 4.05106Z"
        fill="white"
      />
      <path
        d="M7.91227 15.7462C7.69031 15.7462 7.47738 15.6581 7.32048 15.5011C7.16346 15.3442 7.0753 15.1312 7.0753 14.9093V10.278C7.0753 10.056 7.16346 9.84312 7.32048 9.68622C7.47738 9.5292 7.69031 9.44104 7.91227 9.44104C8.13424 9.44104 8.34717 9.5292 8.50407 9.68622C8.66109 9.84312 8.74925 10.056 8.74925 10.278V14.8869C8.75382 14.9991 8.73541 15.111 8.69523 15.2159C8.65517 15.3207 8.59402 15.4162 8.51568 15.4967C8.43734 15.5772 8.34348 15.6408 8.2397 15.6836C8.13591 15.7266 8.02454 15.7478 7.91227 15.7462Z"
        fill="white"
      />
      <path
        d="M10.8137 22.7881H5.45704C4.35776 22.7705 3.30928 22.3223 2.5371 21.5396C1.76493 20.757 1.33075 19.7026 1.32797 18.6032V14.9094C1.32797 14.6874 1.41616 14.4745 1.57312 14.3176C1.73008 14.1605 1.94297 14.0724 2.16494 14.0724H14.1616C14.3835 14.0724 14.5964 14.1605 14.7533 14.3176C14.9104 14.4745 14.9985 14.6874 14.9985 14.9094V18.6032C14.9956 19.7121 14.5537 20.7749 13.7695 21.5591C12.9853 22.3432 11.9227 22.7852 10.8137 22.7881ZM3.00192 15.7463V18.6032C3.00175 19.2596 3.25862 19.8899 3.71751 20.3593C4.1764 20.8285 4.80082 21.0995 5.45704 21.1141H10.8137C11.4796 21.1141 12.1182 20.8495 12.5892 20.3787C13.06 19.9078 13.3246 19.2691 13.3246 18.6032V15.7463H3.00192Z"
        fill="white"
      />
      <path
        d="M27.3773 13.9969C29.5042 13.9969 31.2354 15.7281 31.2354 17.8555C31.2354 19.9829 29.5042 21.7141 27.3773 21.7141C25.2499 21.7141 23.5187 19.9829 23.5187 17.8555C23.5187 15.7281 25.2499 13.9969 27.3773 13.9969ZM27.3773 12.7111C24.5359 12.7111 22.2329 15.014 22.2329 17.8555C22.2329 20.6969 24.5359 22.9999 27.3773 22.9999C30.2183 22.9999 32.5217 20.6969 32.5217 17.8555C32.5217 15.014 30.2183 12.7111 27.3773 12.7111Z"
        fill="white"
      />
      <path
        d="M21.3451 6.93018C19.0416 6.93018 17.1747 7.83941 17.1747 8.96148V10.0463C17.1747 11.1683 19.0416 12.0776 21.3451 12.0776C23.6476 12.0776 25.5151 11.1683 25.5151 10.0463V8.96148C25.5151 7.83941 23.6476 6.93018 21.3451 6.93018ZM21.3451 7.77369C23.3755 7.77369 24.6711 8.55345 24.6711 8.96148C24.6711 9.3695 23.3755 10.1493 21.3451 10.1493C19.3138 10.1493 18.0187 9.3695 18.0187 8.96148C18.0187 8.55345 19.3138 7.77369 21.3451 7.77369Z"
        fill="white"
      />
      <path
        d="M21.3447 13.7096C22.1161 13.7096 22.8385 13.6066 23.4584 13.429C22.8616 13.9538 22.3731 14.6021 22.0288 15.3299C21.8072 15.3475 21.5786 15.3583 21.3447 15.3583C19.0422 15.3583 17.1747 14.4476 17.1747 13.326V12.051C17.1747 12.0304 17.1904 12.0117 17.211 12.0098C17.2213 12.0068 17.2316 12.0117 17.239 12.0225C17.2341 12.0225 17.239 12.0372 17.2674 12.1069C17.671 13.023 19.3429 13.7096 21.3447 13.7096Z"
        fill="white"
      />
      <path
        d="M21.46 17.8556C21.46 18.1057 21.4757 18.3499 21.5085 18.5912C21.4551 18.5942 21.4006 18.5942 21.3447 18.5942C19.0422 18.5942 17.1747 17.6859 17.1747 16.5648V15.2858C17.1747 15.2682 17.1904 15.2505 17.211 15.2476C17.2277 15.2456 17.2385 15.2554 17.2463 15.2672C17.2365 15.2544 17.2262 15.2476 17.2674 15.3457C17.671 16.2608 19.3429 16.9483 21.3447 16.9483C21.4065 16.9483 21.4708 16.9483 21.5321 16.9424C21.483 17.2411 21.46 17.5447 21.46 17.8556Z"
        fill="white"
      />
      <path
        d="M22.8773 21.6907C22.404 21.7839 21.8871 21.8324 21.3447 21.8324C19.0422 21.8324 17.1747 20.9247 17.1747 19.8006V18.5246C17.1747 18.504 17.1904 18.4883 17.211 18.4863C17.2267 18.4834 17.2419 18.4912 17.2468 18.5069C17.2365 18.4942 17.2238 18.4814 17.2674 18.5815C17.671 19.4995 19.3429 20.1861 21.3447 20.1861C21.5424 20.1861 21.7405 20.1782 21.9308 20.1655C22.1671 20.7236 22.4888 21.2385 22.8773 21.6907Z"
        fill="white"
      />
      <path
        d="M28.3487 17.9507C28.4757 17.907 28.4679 17.8541 28.5596 17.7918C28.6513 17.729 28.7268 17.6594 28.7872 17.5819C28.8465 17.5054 28.8906 17.423 28.9191 17.3347C28.9475 17.2465 28.9617 17.1542 28.9617 17.0581C28.9617 16.8894 28.9333 16.7374 28.8764 16.6025C28.8195 16.4681 28.7303 16.3539 28.6076 16.2587C28.4855 16.1636 28.3291 16.091 28.1388 16.04C27.9887 15.9998 27.8146 15.9752 27.6165 15.9669V15.5192H26.9672V15.9625H26.1487H25.8799V16.2975C25.8799 16.395 25.873 16.4549 25.9682 16.4755C25.9765 16.4765 25.9883 16.4784 26.0035 16.4814C26.0192 16.4843 26.0472 16.4892 26.0883 16.4971C26.1295 16.5039 26.0771 16.5137 26.1487 16.527V19.5111C26.0771 19.5234 26.1295 19.5337 26.0883 19.5406C26.0471 19.5479 26.0192 19.5533 26.0035 19.5558C25.9883 19.5582 25.9765 19.5607 25.9682 19.5622C25.8731 19.5827 25.8799 19.6416 25.8799 19.7402V20.0742H26.1487H26.9672V20.49H27.6165V20.0742H27.7008C27.9397 20.0742 28.1515 20.0437 28.3374 19.9829C28.5233 19.9231 28.6792 19.8388 28.8062 19.7319C28.9338 19.625 29.0299 19.497 29.0971 19.3493C29.1633 19.2012 29.1961 19.0404 29.1961 18.8658C29.1962 18.3823 28.9137 18.0782 28.3487 17.9507ZM26.9133 16.5545H27.4493C27.7073 16.5545 27.8966 16.6001 28.0177 16.6913C28.1393 16.7821 28.2001 16.9346 28.2001 17.1484C28.2001 17.3554 28.1325 17.5064 27.9981 17.6006C27.8632 17.6957 27.6661 17.7428 27.4066 17.7428H26.9133V16.5545H26.9133ZM28.3061 19.0904C28.2801 19.167 28.2384 19.2327 28.181 19.2896C28.1227 19.3465 28.0462 19.3921 27.9501 19.4259C27.8544 19.4602 27.7358 19.4774 27.5935 19.4774H26.9133V18.272H27.5911C27.7255 18.272 27.8402 18.2852 27.9349 18.3117C28.0295 18.3382 28.1075 18.3765 28.1678 18.427C28.2286 18.4775 28.2732 18.5378 28.3012 18.6089C28.3301 18.68 28.3444 18.76 28.3444 18.8492C28.3443 18.9345 28.3311 19.0149 28.3061 19.0904Z"
        fill="white"
      />
    </svg>
  );
};
export const PEAnalysis = () => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1948 19.7603C13.9757 19.7603 13.7979 19.938 13.7979 20.1571V20.7549H0.793832V3.74238H1.43473C1.65399 3.74238 1.83165 3.56472 1.83165 3.34547C1.83165 3.12627 1.65393 2.94861 1.43473 2.94861H0.396916C0.177657 2.94861 0 3.12627 0 3.34547V21.1519C0 21.371 0.177715 21.5488 0.396916 21.5488H14.1948C14.4139 21.5488 14.5917 21.371 14.5917 21.1519V20.1572C14.5917 19.9381 14.4139 19.7603 14.1948 19.7603Z"
        fill="white"
      />
      <path
        d="M16.8789 15.5435C16.6597 15.5435 16.482 15.7211 16.482 15.9403V18.1465H3.47787V1.13404H9.69949C9.91875 1.13404 10.0964 0.956326 10.0964 0.737125C10.0964 0.517925 9.91869 0.34021 9.69949 0.34021H3.08089C2.86169 0.34021 2.68398 0.517925 2.68398 0.737125V18.5435C2.68398 18.7627 2.86169 18.9404 3.08089 18.9404H16.8788C17.098 18.9404 17.2757 18.7627 17.2757 18.5435V15.9404C17.2758 15.7211 17.098 15.5435 16.8789 15.5435Z"
        fill="white"
      />
      <path
        d="M16.8789 4.29138C16.6597 4.29138 16.482 4.4691 16.482 4.6883V9.95156C16.482 10.1708 16.6597 10.3485 16.8789 10.3485C17.0981 10.3485 17.2758 10.1708 17.2758 9.95156V4.6883C17.2758 4.46916 17.0981 4.29138 16.8789 4.29138Z"
        fill="white"
      />
      <path
        d="M5.26864 16.7226H7.11546C7.33466 16.7226 7.51238 16.5449 7.51238 16.3257V12.2486C7.51238 12.0294 7.33466 11.8517 7.11546 11.8517H5.26864C5.04938 11.8517 4.87172 12.0294 4.87172 12.2486V16.3257C4.87172 16.5448 5.04938 16.7226 5.26864 16.7226ZM5.66555 12.6455H6.71849V15.9287H5.66555V12.6455Z"
        fill="white"
      />
      <path
        d="M9.24265 13.1818C9.02339 13.1818 8.84573 13.3594 8.84573 13.5786V16.3256C8.84573 16.5448 9.02345 16.7225 9.24265 16.7225H11.0895C11.3087 16.7225 11.4864 16.5448 11.4864 16.3256V14.9662C11.4864 14.747 11.3087 14.5693 11.0895 14.5693C10.8702 14.5693 10.6926 14.747 10.6926 14.9662V15.9287H9.63962V13.5787C9.63956 13.3594 9.46191 13.1818 9.24265 13.1818Z"
        fill="white"
      />
      <path
        d="M13.2166 15.2745C12.9974 15.2745 12.8196 15.4523 12.8196 15.6715V16.3256C12.8196 16.5448 12.9974 16.7225 13.2166 16.7225H15.0634C15.2826 16.7225 15.4604 16.5448 15.4604 16.3256V15.6715C15.4604 15.4523 15.2826 15.2745 15.0634 15.2745C14.8442 15.2745 14.6665 15.4523 14.6665 15.6715V15.9287H13.6135V15.6715C13.6135 15.4523 13.4357 15.2745 13.2166 15.2745Z"
        fill="white"
      />
      <path
        d="M14.433 4.42252C14.278 4.26754 14.0268 4.26749 13.8718 4.42258L11.1855 7.10883C11.0305 7.26387 11.0305 7.51512 11.1856 7.67016C11.3405 7.82514 11.5919 7.8252 11.7469 7.6701L14.1524 5.26458L14.618 5.73011L11.6586 8.68946C11.5036 8.8445 11.5036 9.09586 11.6586 9.25085C11.7361 9.32831 11.8377 9.3671 11.9393 9.3671C12.0409 9.3671 12.1425 9.32831 12.22 9.25085L15.4601 6.01083C15.5346 5.93636 15.5763 5.8354 15.5763 5.73011C15.5763 5.62487 15.5345 5.52386 15.4601 5.44944L14.433 4.42252Z"
        fill="white"
      />
      <path
        d="M5.52646 9.90277C5.60398 9.98017 5.70558 10.019 5.80718 10.019C5.90878 10.019 6.01038 9.98017 6.0879 9.90277L7.1358 8.85482C7.29084 8.69978 7.29084 8.44847 7.1358 8.29343C6.98076 8.13845 6.72945 8.13845 6.57441 8.29343L5.80718 9.06072L5.34159 8.59513L7.1358 6.80098C7.29084 6.64594 7.29084 6.39463 7.1358 6.23965C6.98076 6.08467 6.72945 6.08467 6.57441 6.23965L4.4996 8.31446C4.34456 8.4695 4.34456 8.72081 4.4996 8.87585L5.52646 9.90277Z"
        fill="white"
      />
      <path
        d="M24.9949 15.1671L22.6814 12.8535C22.5264 12.6985 22.2752 12.6985 22.1202 12.8535C21.9651 13.0086 21.9651 13.2599 22.1202 13.4149L24.4337 15.7284C24.5714 15.8662 24.6473 16.0493 24.6473 16.2441C24.6473 16.4388 24.5714 16.6219 24.4337 16.7597C24.1493 17.0441 23.6868 17.0441 23.4024 16.7597L19.7448 13.1021C21.3969 11.7458 22.4528 9.68875 22.4528 7.3894C22.4528 3.31477 19.138 -0.00012207 15.0634 -0.00012207C10.9888 -0.00012207 7.67386 3.31477 7.67386 7.3894C7.67386 11.464 10.9888 14.7789 15.0634 14.7789C16.5508 14.7789 17.9358 14.3357 19.0965 13.5765L22.8411 17.321C23.1287 17.6086 23.5113 17.767 23.918 17.767C24.3248 17.767 24.7074 17.6086 24.9949 17.321C25.5889 16.7272 25.5889 15.7608 24.9949 15.1671ZM15.0634 13.985C11.4265 13.985 8.46775 11.0262 8.46775 7.3894C8.46775 3.75253 11.4266 0.793768 15.0634 0.793768C18.7001 0.793768 21.659 3.75253 21.659 7.3894C21.659 11.0262 18.7002 13.985 15.0634 13.985Z"
        fill="white"
      />
      <path
        d="M15.0634 1.96887C12.0744 1.96887 9.64275 4.40058 9.64275 7.38952C9.64275 10.3785 12.0744 12.8101 15.0634 12.8101C18.0523 12.8101 20.4839 10.3785 20.4839 7.38952C20.4839 4.40058 18.0523 1.96887 15.0634 1.96887ZM15.0634 12.0162C12.5122 12.0162 10.4366 9.94064 10.4366 7.38946C10.4366 4.83828 12.5122 2.7627 15.0634 2.7627C17.6145 2.7627 19.6901 4.83828 19.6901 7.38946C19.6901 9.94064 17.6145 12.0162 15.0634 12.0162Z"
        fill="white"
      />
      <path
        d="M5.06374 3.71987V3.13874C5.06374 2.91954 4.88603 2.74182 4.66683 2.74182C4.44757 2.74182 4.26991 2.91954 4.26991 3.13874V3.71987C4.26991 3.93913 4.44763 4.11679 4.66683 4.11679C4.88603 4.11685 5.06374 3.93919 5.06374 3.71987Z"
        fill="white"
      />
      <path
        d="M6.35819 3.7199V2.47565C6.35819 2.25645 6.18048 2.07874 5.96128 2.07874C5.74208 2.07874 5.56436 2.25645 5.56436 2.47565V3.7199C5.56436 3.93915 5.74208 4.11681 5.96128 4.11681C6.18048 4.11687 6.35819 3.93921 6.35819 3.7199Z"
        fill="white"
      />
      <path
        d="M7.65275 3.71984V3.5174C7.65275 3.2982 7.47503 3.12048 7.25583 3.12048C7.03663 3.12048 6.85892 3.2982 6.85892 3.5174V3.71984C6.85892 3.9391 7.03663 4.11676 7.25583 4.11676C7.47503 4.11682 7.65275 3.93916 7.65275 3.71984Z"
        fill="white"
      />
    </svg>
  );
};
