import { FaSort } from "react-icons/fa";

const PeTableHeaderElement = ({ clickedSort, name, id, selectedId, hasBorder = true, className }) => {
  return (
    <div
      className={`w-full  flex-row items-center justify-between ${
        hasBorder ? "border-r border-neutral-300/20 " : ""
      } px-2 py-1 ${className ? className : 'flex'}`}
      onClick={() => clickedSort(id)}
    >
      {name}
      <FaSort className={`${selectedId === id ? 'text-white' : 'text-neutral-500 hover:text-neutral-300'} cursor-pointer`}/>
    </div>
  );
};
export default PeTableHeaderElement;
