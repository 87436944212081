export const peMock = {
  peRatioDataset: [
    {
      title: "Fjordy Foundry",
      value: 9,
      id: 1,
    },
    {
      title: "Banana Gun",
      value: 2.5,
      id: 2,
    },
    {
      title: "Trojan",
      value: 15,
      id: 3,
    },
    {
      title: "DEXTools",
      value: 5,
      id: 4,
    },
    {
      title: "Whales Market",
      value: 11,
      id: 5,
    },
  ],
  peList: [
    {
      label: "Ethereum",
      chains: "",
      logo: "",
      marketCap: 0,
      holdersRevenue: 0,
      revenue7: 0,
      revenue30: 0,
      revenue365: 0,
      cumulativeRevenue: 0,
      pe: 9,
    },
    {
      label: "Lido",
      chains: "",
      logo: "",
      marketCap: 0,
      holdersRevenue: 0,
      revenue7: 0,
      revenue30: 0,
      revenue365: 0,
      cumulativeRevenue: 0,
      pe: 20,
    },
    {
      label: "Uniswap",
      chains: "",
      logo: "",
      marketCap: 0,
      holdersRevenue: 0,
      revenue7: 0,
      revenue30: 0,
      revenue365: 0,
      cumulativeRevenue: 0,
      pe: 12,
    },
    {
      label: "AAVE",
      chains: "",
      logo: "",
      marketCap: 0,
      holdersRevenue: 0,
      revenue7: 0,
      revenue30: 0,
      revenue365: 0,
      cumulativeRevenue: 0,
      pe: 5,
    },
    {
      label: "Maker",
      chains: "",
      logo: "",
      marketCap: 0,
      holdersRevenue: 0,
      revenue7: 0,
      revenue30: 0,
      revenue365: 0,
      cumulativeRevenue: 0,
      pe: 30,
    },
  ],
};

export const peList = [
  {
    id: 1,
    name: "Ethereum",
    chains: "",
    logoUrl: "https://icons.llamao.fi/icons/chains/rsz_ethereum",
    marketCap: 453,
    peRatio: 40,
    revenue1day: 671,
    revenue1week: 204,
    revenue1month: 980,
    revenue1year: 732,
    revenues: {},
    holdersRevenue: 85,
    cumulativeRevenue: 912,
  },
  {
    id: 2,
    name: "Lido",
    chains: "",
    logoUrl: "https://icons.llamao.fi/icons/protocols/lido",
    marketCap: 576,
    peRatio: 30,
    revenue1day: 348,
    revenue1week: 59,
    revenue1month: 884,
    revenue1year: 937,
    revenues: {},
    holdersRevenue: 129,
    cumulativeRevenue: 461,
  },
  {
    id: 3,
    name: "Uniswap",
    chains: "",
    logoUrl: "https://icons.llamao.fi/icons/protocols/uniswap-v3",
    marketCap: 143,
    peRatio: 15,
    revenue1day: 319,
    revenue1week: 466,
    revenue1month: 576,
    revenue1year: 800,
    revenues: {},
    holdersRevenue: 237,
    cumulativeRevenue: 683,
  },
  {
    id: 4,
    name: "Solana",
    chains: "https://icons.llamao.fi/icons/chains/rsz_solana",
    logoUrl: "https://icons.llamao.fi/icons/chains/rsz_solana",
    marketCap: 350,
    peRatio: 46,
    revenue1day: 15,
    revenue1week: 992,
    revenue1month: 640,
    revenue1year: 49,
    revenues: {},
    holdersRevenue: 231,
    cumulativeRevenue: 741,
  },
  {
    id: 5,
    name: "Bitcoin",
    chains: "",
    logoUrl: "https://icons.llamao.fi/icons/chains/rsz_bitcoin",
    marketCap: 382,
    peRatio: 39,
    revenue1day: 643,
    revenue1week: 216,
    revenue1month: 308,
    revenue1year: 522,
    revenues: {},
    holdersRevenue: 409,
    cumulativeRevenue: 791,
  },
  {
    id: 6,
    name: "Ethereum 2",
    chains: "",
    logoUrl: "https://icons.llamao.fi/icons/chains/rsz_ethereum",
    marketCap: 453,
    peRatio: 40,
    revenue1day: 671,
    revenue1week: 204,
    revenue1month: 980,
    revenue1year: 732,
    revenues: {},
    holdersRevenue: 85,
    cumulativeRevenue: 912,
  },
  {
    id: 7,
    name: "Lido 2",
    chains: "",
    logoUrl: "https://icons.llamao.fi/icons/protocols/lido",
    marketCap: 576,
    peRatio: 30,
    revenue1day: 348,
    revenue1week: 59,
    revenue1month: 884,
    revenue1year: 937,
    revenues: {},
    holdersRevenue: 129,
    cumulativeRevenue: 461,
  },
  {
    id: 8,
    name: "Uniswap 2",
    chains: "",
    logoUrl: "https://icons.llamao.fi/icons/protocols/uniswap-v3",
    marketCap: 143,
    peRatio: 15,
    revenue1day: 319,
    revenue1week: 466,
    revenue1month: 576,
    revenue1year: 800,
    revenues: {},
    holdersRevenue: 237,
    cumulativeRevenue: 683,
  },
  {
    id: 9,
    name: "Solana 2",
    chains: "https://icons.llamao.fi/icons/chains/rsz_solana",
    logoUrl: "https://icons.llamao.fi/icons/chains/rsz_solana",
    marketCap: 350,
    peRatio: 46,
    revenue1day: 15,
    revenue1week: 992,
    revenue1month: 640,
    revenue1year: 49,
    revenues: {},
    holdersRevenue: 231,
    cumulativeRevenue: 741,
  },
  {
    id: 10,
    name: "Bitcoin 2",
    chains: "",
    logoUrl: "https://icons.llamao.fi/icons/chains/rsz_bitcoin",
    marketCap: 382,
    peRatio: 39,
    revenue1day: 643,
    revenue1week: 216,
    revenue1month: 308,
    revenue1year: 522,
    revenues: {},
    holdersRevenue: 409,
    cumulativeRevenue: 791,
  },
];

export const singlePe = {
  graphInfo: [
    {
      peRatio: 34.56,
      apy: 7.89,
      price: 456.78,
      date: "2021-01-01",
    },
    {
      peRatio: 12.34,
      apy: 3.21,
      price: 987.65,
      date: "2021-01-02",
    },
    {
      peRatio: 45.67,
      apy: 1.23,
      price: 654.32,
      date: "2021-01-03",
    },
    {
      peRatio: 23.45,
      apy: 4.56,
      price: 321.09,
      date: "2021-01-04",
    },
    {
      peRatio: 56.78,
      apy: 9.87,
      price: 876.54,
      date: "2021-01-05",
    },
    {
      peRatio: 34.21,
      apy: 2.34,
      price: 543.21,
      date: "2021-01-06",
    },
    {
      peRatio: 67.89,
      apy: 6.78,
      price: 234.56,
      date: "2021-01-07",
    },
    {
      peRatio: 89.01,
      apy: 8.90,
      price: 789.01,
      date: "2021-01-08",
    },
    {
      peRatio: 78.90,
      apy: 5.67,
      price: 123.45,
      date: "2021-01-09",
    },
    {
      peRatio: 90.12,
      apy: 3.45,
      price: 678.90,
      date: "2021-01-10",
    }
  ],
  projectInfo: {
    name: "Banana Gun",
    marketCap: 10000,
    dailyVolume: 2000,
    fullyDilutedMarketCap: 3000,
    liquidity: 40000,
    holders: 40500,
    circulatingSupply: 24030,
    tokenFake: {
      unlockPerc: 43,
      max: 2.302,
      dailyUnlocks: 80.4,
      nextEvent: {
        value: 79.4,
        date: '2024-08-10'
      }
    },
    holdersGraphInfo: [
      {
        holders: 45000,
        date: "2021-01-01",
      },
      {
        holders: 49000,
        date: "2021-01-02",
      },
      {
        holders: 42000,
        date: "2021-01-03",
      },
      {
        holders: 39000,
        date: "2021-01-04",
      },
      {
        holders: 36000,
        date: "2021-01-05",
      },
      {
        holders: 40000,
        date: "2021-01-06",
      },
      {
        holders: 42000,
        date: "2021-01-07",
      },
      {
        holders: 43000,
        date: "2021-01-08",
      },
      {
        holders: 43000,
        date: "2021-01-09",
      },
      {
        holders: 45000,
        date: "2021-01-10",
      },
    ],
  },
};
