import AuthenticatedContainer from "../components/containers/AuthenticatedContainer";
import PEMainCharts from "../components/containers/PE/PEMainCharts";
export default function PEAnalysis() {
  return (
    <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-10">
      <AuthenticatedContainer>
        <PEMainCharts />
      </AuthenticatedContainer>
    </main>
  );
}
