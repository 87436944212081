import Button from "./Button";
/* import { usePathname } from "next/navigation";
 */ const LinkButton = ({ linkPath, label, icon, disabled }) => {
  /* const path = usePathname() */

  return (
    <a
      href={linkPath}
      className={`max-md:text-xl ${disabled ? "pointer-events-none" : ""}`}
    >
      <Button label={label} icon={icon} />
    </a>
  );
};

export default LinkButton;
