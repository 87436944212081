export const peRatioSorter = (dataset) => {
  const mappedDataser = dataset.map((el) => {
    return {
      title: el.name,
      value: el.peRatio,
      id: el.id,
    };
  });
  return mappedDataser.sort((a, b) => a.value - b.value);
};

export const sortObjectsArray = (array, key, descending = false) => {
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return descending ? 1 : -1;
    }
    if (a[key] > b[key]) {
      return descending ? -1 : 1;
    }
    return 0;
  });
};

export function timeUntil(targetDate) {
  const now = new Date();
  const diff = new Date(targetDate) - now;
  if (diff <= 0) {
      return {
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
      };
  }
  const seconds = Math.floor((diff / 1000) % 60);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));

  return {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
  };
}