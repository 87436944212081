import { useEffect, useState } from "react";
import axios from "axios";

export const useGetHomeData = () => {
  const [homeData, setHomeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const tester = (val) => {
    setHomeData(val)
  }
  useEffect(() => {
    setLoading(true);
    const savedData = localStorage.getItem("homepage-data");
    const fetchDataAndStore = async () => {
      try {
        const response = await axios.get(
          "https://bcarmy-temp.vercel.app/api/get-home-data"
        );
        const dataToStore = response.data;
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 3);
        localStorage.setItem(
          "homepage-data",
          JSON.stringify({
            data: dataToStore,
            expiration: expirationTime.getTime(),
          })
        );
        console.log(response.data)
        tester(response.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (!savedData) {
      setLoading(true);

     fetchDataAndStore();
    } else {
      const currentTime = new Date().getTime();
      const savedExpirationTime = JSON.parse(savedData).expiration;
      if (currentTime > savedExpirationTime) {
        localStorage.removeItem("homepage-data");
        setLoading(true);
        fetchDataAndStore();
      } else {
        setHomeData(JSON.parse(savedData).data);
        setLoading(false);
      }
    }
  }, []);
  return { homeData, loading };
};
