import { useEffect, useState } from "react";
import { useReadContract } from "wagmi";
import NoBalance from "../NoBalance";

const Paywall = ({ children, address }) => {
  const [balance, setBalance] = useState(null);
  const result = useReadContract({
    abi: [
      {
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    address: "0x294d44386c9ff8c38659511297a2e08d829f9336",
    functionName: "balanceOf",
    args: [address],
  });
  useEffect(() => {
    if(result && result.data) {
      setBalance(Number.parseInt(result.data.toString()));
    }
  }, [result]);
  return (
    <>
      {balance >= 0 ? (
        <div className="w-full h-full flex flex-col">{children}</div>
      ) : <NoBalance />}
    </>
  );
};
export default Paywall;
