"use client";
import axios from "axios";
import { useEffect, useState } from "react";
import PeLinechart from "./PeLinechart";
import { singlePe } from "../../../mocks/pemock";
import Loader from "../../Loader";
import PeSingleProjectInfos from "../../PE/PeSingleProjectInfos";
import PeHoldersChart from "./PeHoldersChart";
import { FaChevronLeft } from "react-icons/fa6";
import TwoColsDescription from "../../TwoColsDescription";

const texts = {
  holders: {
    title: "Holders Revenue",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem adipisci officia doloremque, soluta repellat molestiae sunt dolor aut tenetur nesciunt dolore expedita eos magnam provident odit architecto sint repellendus rerum.",
  },
  project: {
    title: "Project Revenue",
    paragraph:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem adipisci officia doloremque, soluta repellat molestiae sunt dolor aut tenetur nesciunt dolore expedita eos magnam provident odit architecto sint repellendus rerum.",
  },
};
const PeSingleElementCharts = ({ id, goBack }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [holdersRevText, setHolderRevText] = useState(null);
  const [projectRevText, setProjectRevText] = useState(null);

  useEffect(() => {
    // here fetch the data using the id
    const promises = [
      axios.get(`https://api.llama.fi/summary/fees/${id}?dataType=dailyFees`),
      axios.get(`https://api.bc.army/api/pe-data/${id}/`),
    ];
    const promisesResolved = promises.map((promise) =>
      promise.catch((error) => ({ error }))
    );

    function checkFailed(then) {
      return function (responses) {
        const someFailed = responses.some((response) => response.error);
        if (someFailed) {
          throw responses;
        }
        return then(responses);
      };
    }

    setIsLoading(true);
    axios
      .all(promisesResolved)
      .then(
        checkFailed(([texts, res]) => {
          if (texts?.data?.methodology) {
            if (texts?.data?.methodology?.HoldersRevenue) {
              setHolderRevText(texts.data.methodology.HoldersRevenue);
            }
            if (texts?.data?.methodology?.ProtocolRevenue) {
              setProjectRevText(texts.data.methodology.ProtocolRevenue);
            }
          }
          setData(res.data);
          setIsLoading(false);
        })
      )
      .catch((err) => {
        console.log("FAIL", err);
        if (err[1]?.status === 200) {
          setData(err[1].data);
        }
        setIsLoading(false);
      });
  }, []);
  return (
    <div className="w-full pb-5 max-md:h-full max-md:max-h-screen overflow-y-auto">
      <div
        className="flex flex-row items-center hover:text-ui-red cursor-pointer text-sm"
        onClick={goBack}
      >
        <FaChevronLeft /> Go back
      </div>
      {isLoading ? (
        <div className="w-full h-screen flex items-center justify-center flex-grow">
          <Loader />
        </div>
      ) : null}
      {!isLoading && data ? (
        <div className="w-full pt-5">
          <div className="text-xl mb-2.5 uppercase">{data.projectInfo.name}</div>
          <div className="h-[40vh] w-full">
            <PeLinechart numbers={data.graphInfo} />
          </div>
          <div className="flex flex-col md:flex-row w-full mt-5 gap-x-5 md:gap-x-10">
            <div className="w-full md:w-[40%]">
              <PeSingleProjectInfos infos={data.projectInfo} />
            </div>
            <div className="w-full md:h-[400px] mt-5 md:mt-0 pb-10 md:pb-0">
              {/* <TokenUnlock info={singlePe.projectInfo.tokenFake} /> */}
              <TwoColsDescription
                textHolders={holdersRevText}
                textProject={projectRevText}
                token={id}
              />
              <PeHoldersChart numbers={singlePe.projectInfo.holdersGraphInfo} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default PeSingleElementCharts;
