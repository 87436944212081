export const TokenKpis = ({
  value,
  label,
}) => {
  return (
    <div className="flex flex-col items-center">
      <div className="uppercase font-semibold text-xs whitespace-nowrap oswald mb-1">
        {label}
      </div>

      <div className="w-40 py-2 bg-ui-black text-white rounded-xl flex flex-col items-center justify-center">
        <div className="relative w-full flex items-center justify-center gap-x-2">
          <div className="oswald font-bold text-lg 2xl:text-sm">{value}</div>
        </div>
      </div>
    </div>
  );
};
