import Navigation from "./Navigation";
import { SocialButtons } from "./SocialButtons";

const Sidebar = () => {
  return (
    <div className="hidden md:flex flex-col justify-between h-screen w-80 flex-shrink-0 bg-ui-dark-gray pb-5 pt-24">
      <Navigation />
      <div className="w-full">
       <SocialButtons />
      </div>
    </div>
  );
};
export default Sidebar;
