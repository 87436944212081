const NoBalance = () => {
  return (
    <div
      className={`bg-ui-dark-gray rounded-[4px] flex flex-col items-center justify-center md:h-[30vh] md:w-[50vw] p-10 md:p-20 mx-auto`}
    >
      <h2 className="oswald text-xl md:text-3xl text-center uppercase">
        We’re Sorry.
      </h2>
      <h3 className="hidden md:block text-xl mt-5 text-center">
      Not enough $ARMY in balance, purchase X more to continue.
      </h3>
      <div>PLACEHOLDER: Here a button to open a modal to buy tokens</div>
    </div>
  );
};
export default NoBalance;
