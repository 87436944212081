import { useEffect } from "react";
import OnlyLogged from "../OnlyLogged";
import { useAccount } from "wagmi";
import Paywall from "./Paywall";

const AuthenticatedContainer = ({ children }) => {
  const account = useAccount();
  useEffect(() => {
    console.log("TEST efc");
  }, [account]);
  return (
    <>
      {account?.isConnected && account?.address ? (
        <Paywall address={account.address}>{children}</Paywall>
      ) : (
        <OnlyLogged />
      )}
    </>
  );
};
export default AuthenticatedContainer;
