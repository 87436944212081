import { FaSort } from "react-icons/fa";

const TableHeaderElement = ({
  clickedSort,
  name,
  id,
  dida,
  selectedId,
  hasBorder = true,
  className,
}) => {
  return (
    <div
      className={`w-full h-18 flex flex-row items-start relative justify-between cursor-pointer pb-2 ${
        hasBorder ? "border-r border-neutral-300/20 " : ""
      } px-2 py-1 ${className ? className : "flex"}`}
      onClick={() => clickedSort(id)}
    >
      <div className="flex flex-col flex-start">
        <div className="text-sm">{name}</div>
        <div className="opacity-50 text-xs">{dida}</div>
      </div>
      <FaSort
        className={`${
          selectedId === id
            ? "text-white"
            : "text-neutral-500 hover:text-neutral-300"
        } cursor-pointer`}
      />
      {/*  {
          selectedId === id && <div className="w-full absolute bottom-0 h-1 bg-red-500 left-0"></div>
        } */}
    </div>
  );
};
export default TableHeaderElement;
