import { BurnCard } from "../components/BurnCard";

function BurnPage() {
  const tokens = [
    {
      classHover: "banana-shadow ",
      img: "/tokens/banana.png",
      path: "https://burn.bc.army/banana",
    },
    {
      classHover: "dork-shadow ",
      img: "/tokens/dork.png",
      path: "https://burn.bc.army/dork",
    },
    {
      classHover: "alpaca-shadow ",
      img: "/tokens/alpaca.png",
      path: "https://burn.bc.army/alpaca",
    },
    {
      classHover: "rollbit-shadow ",
      img: "/tokens/rollbit.png",
      path: "https://burn.bc.army/rollbit",
    },
    {
      classHover: "woof-shadow",
      img: "/tokens/woof.png",
      path: "https://burn.woofwork.io/",
    },
    {
      classHover: "dex-shadow ",
      img: "/tokens/dex.png",
      path: "https://burn.dextools.io/",
    },
  ];
  return (
    <main className="w-full h-full flex min-h-screen flex-col items-center justify-center px-5 md:px-10 pt-2 gap-y-5">
      {/* <div className="w-full h-72  border border-neutral-300/20  rounded-sm p-5 flex items-center justify-center ">
        <h2 className="text-2xl uppercase font-semibold">Dextools</h2>
      </div>
      <div className="w-full h-72  border border-neutral-300/20  rounded-sm flex items-center justify-center ">
      <iframe src="https://burn.bc.army/alpaca" title="Burn Dashboard" style={{width: '100%', height: '100%'}} />
      </div> */}
      <div className="oswald text-3xl font-bold uppercase">Burn Dashboards</div>
      <div className=" grid grid-cols-2 md:grid-cols-3 gap-10 items-center justify-center ">
        {tokens.map((t) => (
          <BurnCard
            key={t.classHover}
            classHover={t.classHover}
            path={t.path}
            img={t.img}
          />
        ))}
      </div>
    </main>
  );
}
export default BurnPage;
